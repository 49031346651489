*{
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  text-decoration: none;
  /* text-transform: capitalize; */
  color: #fff;
}

#root{
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: black;
}
button{
  cursor: pointer;
}